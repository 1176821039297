<template>
  <div id="result_wrapper">
    <div class="content_box">
      <div class="inner_max">
        <el-row>
          <el-col :span="24">
            <el-card class="p-3 p-md-5">
              <div
                class="result_content"
                v-show="waiting"
                v-loading="waiting"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(25, 35, 47, 0.3)"
              >
                <p>{{ $t('thirdPartyDeposit.result.countDownMsg', { countDown: countDown }) }}</p>
              </div>
              <div class="result_content" v-if="failure">
                <i class="icon el-icon-circle-close text-magenta"></i>
                <p>{{ $t('thirdPartyDeposit.result.failureMsg') }}</p>
                <router-link :to="{ name: 'home' }" class="el-button">{{ $t('common.button.bkToHm') }}</router-link>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { apiThirdPartyDepositResult } from '@/resource';

export default {
  name: 'ThirdPartyDepositFailed',
  data() {
    return {
      waiting: true,
      failure: false,
      countDown: 30,
      excludeResultList: ['vload']
    };
  },
  methods: {
    getPathName() {
      return window.location.pathname;
    },
    waitingCountDown() {
      setInterval(() => {
        this.countDown--;
      }, 1000);
    },
    excludeResult(paymentType) {
      return this.excludeResultList.includes(paymentType);
    },
    getPaymentType() {
      let paymentType = this.getPathName().split('/')[1];
      if (paymentType === 'thirdPartyDepositFailed') paymentType = this.getPathName().split('/')[2];
      console.log(paymentType);
      this.getResult(paymentType);
    },
    getResult(paymentType) {
      if (!this.excludeResult(paymentType)) {
        this.postApiThirdPartyDepositResult(paymentType);
      } else {
        this.showFailed();
      }
    },
    showFailed() {
      this.failure = true;
      this.waiting = false;
    },
    postApiThirdPartyDepositResult(paymentType) {
      apiThirdPartyDepositResult(paymentType, this.$route.query).finally(result => {
        this.showFailed();
      });
    }
  },
  mounted() {
    if (this.$route.meta.restrictApi) {
      this.showFailed();
    } else {
      this.waiting = true;
      this.waitingCountDown();
      this.getPaymentType();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/thirdPartyDepositResult.scss';
</style>
